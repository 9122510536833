import * as React from "react";
import { FAQItem } from "../components/FAQItem";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";

const title = <span><strong>Reviti</strong> Payback Terms And Conditions</span>
// markupti
const IndexPage = () => {
  return (
    <Layout title={"Reviti - Home"}>
           <Header title={title} subtitle={""} currentPage={"Terms and Conditions"}/>
           <div className="container-padding relative flex flex-col md:flex-row my-30 terms-container"> 
<div className={"w-full md:w-2/3 flex flex-col"}>
      <p>
        <span className="article-section-heading">
          About the Offer (the “Offer”)
        </span>
      </p>
      <p>
        These terms and conditions apply to the <strong>Reviti</strong> Payback
        (the “<strong>Payback</strong>”) offered to you as a{" "}
        <strong>Reviti</strong> Term Life policyholder when you have ceased
        using all tobacco, nicotine and vaping products (as described in
        paragraph 3 below) for at least 12 months, you have paid at least 12
        monthly premiums and your policy remains in force, with your monthly
        premium payments paid in full when they were due at the time the Payback
        is due to be paid.
      </p>
      <p>
        By claiming your Payback, you are deemed to have accepted these terms
        and conditions.
      </p>
      <p>
        Please read these terms and conditions carefully before deciding to
        proceed.
      </p>
      <p>
        The Offer is being provided by <strong>Reviti</strong>, the trading name
        of <strong>Reviti</strong> Limited.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Payback Amount</span>
      </p>
      <p>
        Payback will be equal to the total amount of your monthly premiums paid
        over the preceding twelve months prior to you qualifying for Payback.
        For example, if your monthly premium payments are £25 then your Payback
        will be 12 X £25 = £300.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">Qualification for the Offer</span>
      </p>
      <ol>
        <li>
          The Offer is only open to UK residents who are aged 18 years and above
          who have purchased a new <strong>Reviti</strong> Term Life policy
          online at{" "}
          <a
            href="/{localLink:umb://document/faf680961f9648e2b33add3293e6846c}"
            title="Home"
          >
            www.revitilife.com
          </a>
          <a
            href="/{localLink:umb://document/faf680961f9648e2b33add3293e6846c}"
            title="Home"
          ></a>{" "}
          or purchase via our contact centre between 00:01 GMT on 2 August 2021
          and 23:59 GMT on 31 January 2022 (the “<strong>Offer Period</strong>
          ”). Purchases outside the Offer Period are not eligible for this
          Offer.
        </li>
        <li>
          The Offer applies to new <strong>Reviti</strong> Term Life policies
          only. You will not receive Pay Payback if you have an existing{" "}
          <strong>Reviti</strong> Term Life policy.
        </li>
        <li>
          You must have ceased to use all tobacco and nicotine products, meaning
          cigarettes, cigars, pipe tobacco or other combustible tobacco
          products, vapes, e-cigarettes (whether or not they include nicotine)
          heated tobacco, or other non-combustible nicotine containing products
          including gum, pouches and patches, for a continuous period of at
          least 12 months within the first 36 months of purchasing your{" "}
          <strong>Reviti</strong> Term Life policy.
        </li>
        <li>
          Your <strong>Reviti</strong> Term Life policy must have been in force
          for (i) 12 months or more and (ii) no greater than 36 months at the
          time you claim the Payback. In addition, you must have paid all your
          monthly premium payments in full when they were due. No Payback will
          be issued if you cancel your policy prior to the Payback being due to
          be paid or you miss or are late in paying a monthly premium payment.
        </li>
        <li>
          In order to claim the Payback you must complete and sign the Payback
          declaration which you can find on{" "}
          <a
            href="/{localLink:umb://document/faf680961f9648e2b33add3293e6846c}"
            title="Home"
          >
            www.revitilife.com
          </a>
          . Please return the signed declaration to{" "}
          <a href="mailto:support@revitilife.com">support@revitilife.com</a>
        </li>
        <li>
          If you ceased to use all tobacco, nicotine and vaping products (as
          described in paragraph 3 above) for 12 months or more prior to
          purchasing your <strong>Reviti</strong> Term Life policy then you will
          have received ex-smoker insurance rates and will not be eligible for
          the Payback. If however you ceased to use all tobacco, nicotine and
          vaping products (as described in paragraph 3 above) less than 12 month
          prior to purchasing your <strong>Reviti</strong> Term Life policy then
          you will be eligible for the Payback once your <strong>Reviti</strong>{" "}
          Term Life policy has been in force for 12 months and you satisfy all
          the conditions of the Offer.
        </li>
        <li>
          Only one Payback will be paid per <strong>Reviti</strong> Term Life
          policy taken out in your name during the Offer Period.
        </li>
      </ol>
      <p> </p>
      <p>
        <span className="article-section-heading">Receiving your Payback</span>
      </p>
      <p>
        Upon receipt by <strong>Reviti</strong> of the completed and signed
        Payback declaration and subject to (i) all your Reviti Term Life policy
        premiums being paid in full when they were due (ii) your{" "}
        <strong>Reviti</strong> Term Life being in force and (iii) you complying
        with all the terms and conditions of the Offer, including you
        undertaking a test to validate your smoking declaration (if required)
        then <strong>Reviti</strong> will pay the equivalent to the amount you
        paid in monthly premiums on your <strong>Reviti</strong> Term policy in
        the preceding 12 months. The payment will be made directly to you via a
        secure payment method.
      </p>
      <p>
        If you don't receive the Payback within 30 days of you sending us your
        signed Payback declaration, then please contact Customer Support at{" "}
        <a href="mailto:support@revitilife.com">support@revitilife.com</a> for
        assistance.
      </p>
      <p> </p>
      <p>
        <span className="article-section-heading">General</span>
      </p>
      <ol>
        <li>
          <strong>Reviti</strong> will not be liable to compensate you if you
          are unable to benefit from this promotion because your{" "}
          <strong>Reviti</strong> Term Life policy is not in force or without
          all monthly premium payments being paid in full when they were due at
          the time the Offer is due to be paid.​
        </li>
        <li>
          The Offer is not sponsored by, endorsed, or administered by, or
          associated with, Scottish Friendly Society.
        </li>
        <li>
          <strong>Reviti</strong> reserves the right at its sole discretion, to
          cancel or modify this Offer and/or Terms and Conditions at any time,
          for any reason and without prior notice or explanation. This includes
          terminating the Offer Period early.
        </li>
        <li>
          <strong>Reviti</strong> accepts no responsibility for any loss,
          damages, liabilities, or disappointment incurred or suffered by you as
          a result of you participating in the Offer. <strong>Reviti</strong>{" "}
          further disclaims liability for any damage to your or any other
          person's computer relating to or resulting from participation in or
          downloading any materials in connection with the Offer.
        </li>
        <li>
          <strong>Reviti</strong> shall not be liable for any failure to comply
          with its obligations where the failure is caused by something outside
          its reasonable control.
        </li>
        <li>
          You must answer and complete declarations honestly and truthfully.
          There may be occasions, in line with the industry, where{" "}
          <strong>Reviti</strong> will ask you to undertake a test to validate
          your smoking declaration and the offer of Payback will require you to
          consent to this test.
        </li>
        <li>
          These terms and conditions shall be governed by and construed in
          accordance with the laws of England and Wales. The Courts of England
          and Wales shall have exclusive jurisdiction in connection with these
          terms and conditions.
        </li>
      </ol>
      <p> </p>
      </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
